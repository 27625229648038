.auth { 
    background-color: var(--dark-dark);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.auth .container { 
    max-width: 400px;
    background-color: var(--white-light);
    display: flex;
    border-radius: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
}

.auth .brand { 
    background-color: var(--white-light);
    width: 160px;
    height: 160px;
    margin-bottom: -80px;
    z-index: 200;
    border-radius: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.auth .brand img {
    max-width: 80px;
}

.auth .title {
    color: var(--third-color);
    font-weight: 300;
    text-align: center;
    color: var(--dark-dark);
    text-transform: uppercase;
}

.auth .form {  
    background-color: var(--third-color);
    width: 100%;
    padding: 20px;
    border-radius: 20px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
