.box {
    padding: 10px;
    margin: 10px;
}

.box .subtitle{
    color: var(--dark-dark);
    font-weight: 400;
    text-align: center;
}

.box .imagePreview {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    padding: 5px;
}

.box .imagePreview img{
    border-radius: 10px;
    max-height: 200px;
}