.screens {
    flex: 1;
    background-color: var(--white-light);
    padding: 20px;
    padding-top: 70px;
    overflow-y: scroll;
}

.screens .error{
    background-color: #dc3545;
    color: #fff;
    font-size: 14px;
    padding: 4px 12px;
    border-radius: 4px;
    text-align: center;
    display: none;
}

.screens .success{
    background-color: #28a745;
    color: #fff;
    font-size: 14px;
    padding: 4px 12px;
    border-radius: 4px;
    text-align: center;
    display: none;
}

.screens .warning{
    background-color: #ffc107;
    color: #fff;
    font-size: 14px;
    padding: 4px 12px;
    border-radius: 4px;
    text-align: center;
}

.screens input, .screens textarea{
    border: 1px solid #c2c2c2!important;
    border-radius: 4px!important;
    background-color: #f3f3f3!important;
    padding: 10px;
    width: 100%;
}

.screens input:focus, .screens textarea:focus{
    box-shadow: 0 0 0 0!important;
    border: 1 none!important;
    outline: 0!important;
    width: 100%;
}

.screens .title{
    font-size: 24px;
    color: #353535;
    font-weight: 600;
    text-align: center;
    font-family: sans-serif;
}

.screens .subtitle{
    font-size: 18px;
    color: #353535;
    font-weight: 600;
    margin-bottom: 20px;
    font-family: sans-serif;
}

.screens label{
    color: #353535;
    font-size: 16px;
    width: 100%;
    font-family: sans-serif;
}

.screens .button{
    border: 2px solid var(--primary-color);
    background-color: var(--primary-color);
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    border-radius: 4px;
    text-transform: uppercase;
    margin: 0 auto;
    padding: 5px 15px;
}

.screens .button:focus{
    box-shadow: 0 0 0 0!important;
    border: 1 none!important;
    outline: 0!important;
}

.screens .button:hover{
    border: 2px solid #c2c2c2;
    background-color: #c2c2c2;
    color: var(--primary-color);
    transition: background-color .4s;
    transition: color .1s;
}

.screens .box {
    border: 1px solid #c2c2c2;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 30px;
}

.screens .line {
    padding: 5px 0;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.screens .line.filters {
    /* nothing yet */
}

.screens .line .column {
    width: 100%;
    padding: 5px;
}

.screens .line .column.filters {
    padding: 0;
    display: flex;
}

.screens .line .column .image {
    cursor: pointer;
}

.help {
    position: relative;
    cursor: pointer;    
    margin: 0 5px;
}    

.help i.far.fa-question-circle{
    font-size: 18px;
}

.help .message{
    color: #353535;
    background-color: #f3f3f3;
    padding: 4px 8px;
    border-radius: 4px;
    position: absolute;
    width: 250px;
    min-height: 40px;
    font-size: 12px;
    bottom: -40px;
    left: -20px;
    display: none;
    justify-content: center;
    align-items: center;
    border: 1px solid #c2c2c2;
    text-align: justify;
    z-index: 300;
}

.help:hover i.far.fa-question-circle {
    color: var(--primary-color);
}   

.help:hover .message {
    display: flex;
}

.screens .lineTable {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.screens .lineTable span{
    font-size: 14px;
    color: #353535;
    font-weight: 600;
    text-align: center;
}

.screens .lineTableContent {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    border: 1px solid #c2c2c2;
}

.screens .lineTableContent:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.screens .lineTableHeader {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    min-height: 40px;
    background-color: #c2c2c2;
}

.screens .columnTable {
    text-align: center;
    border-right: 1px solid #c2c2c2;
    padding: 0;
}

.screens .columnTable:last-of-type {
    border-right: none;
}

.screens .buttonIcon {
    text-decoration: none;
    cursor: pointer;
    margin: 10px;
    font-size: 18px;
    color: #353535;
    border: none;
    outline: none;
    box-shadow: none;
}

.screens .buttonIcon:hover {
    color: var(--primary-color);
    transition: color .3s;
}

.screens .legend {
    color: var(--dark-light);
    font-size: 12px;
}

.screens .lineLegend {
    margin: 15px;
}