.input {  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.input .input { 
    outline: none;
    box-shadow: none;
    background: var(--white-dark)!important;
    border-radius: 60px!important;
    border: 2px solid var(--white-dark)!important;
    color: var(--dark-dark);
    padding: 10px;
    margin: 10px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.input .label {
    color: var(--second-color);
    font-size: 16px;
    font-weight: 300;
    display: flex;
    align-items: center;
    vertical-align: middle;
    margin-left: 10px;
    width: 100%;
}

.input .label .textLabel {
    color: var(--dark-dark);
    font-size: 16px;
    font-weight: 400;
}

.input .label .help {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    margin-left: 5px;
    position: relative;
}

.input .label .help .message {
    max-width: 250px;
    background-color: var(--white-dark);
    border: 1px solid var(--white-dark);
    color: var(--dark-dark);
    padding: 10px;
    font-size: 12px;
}

.input .label .help .icon {
    color: var(--dark-dark);
    font-size: 22px;
}