.boxScreen {
    padding: 10px;
    margin: 10px;
}

.boxScreen .title {
    color: var(--primary-color);
    font-weight: 400;
    text-transform: uppercase;
}

.boxScreen .table {
    height: 100%;
}

.boxScreen .table .line .column {
    border-right: 1px solid #c2c2c2;
    padding: 5px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.boxScreen .table .line .column:last-of-type {
    border: none;
}

.boxScreen .table .line{
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    position: relative;
   
}

.boxScreen .table .line.head{
    border: 1px solid #c2c2c2;
    background-color: var(--white-dark);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-weight: 600;

}

.boxScreen .table .line.body .file{
    cursor: pointer;
}

.boxScreen .table .line.body .areaAvatar{
    position: relative;
    cursor: pointer;
    border: 2px solid var(--primary-color);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    overflow: hidden;
}

.boxScreen .table .line.body .avatar{
    position: relative;
    height: 100%;
}

.boxScreen .table .line.body{
    border: 1px solid #c2c2c2;
    border-top: none;
    height: 120px;
}

.boxScreen .table .line.body.detailsUser {
    background-color: var(--white-dark);
    border: 2px solid var(--primary-color);
    height: 100%;
}

.boxScreen .table .line.body.detailsUser .columnDetailsUser{
    display: contents;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    height: 100%;

}

.boxScreen .table .line.body:last-of-type{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.boxScreen .btnOperation {
    box-shadow: none;
    outline: none;
    border: 1px solid var(--dark-dark);
    background-color: var(--white-dark);
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 10px;
    margin: 10px;
    color: var(--dark-dark);
}

.boxScreen .btnOperation:hover {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    color: var(--white-light);
    transition: 3s;
}

.boxScreen .spanColumn {
    width: 100%;
    cursor: pointer;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.boxScreen .spanColumn .spanColumnNoHover {
    white-space: nowrap;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.boxScreen .spanColumn .spanColumnHover {
    position: absolute;
    left: 20px;
    top: 20px;
    min-width: 300px;
    padding: 5px;
    border-radius: 4px;
    color: var(--dark-dark);
    font-size: 14px;
    padding: 5px;
    z-index: 9999;
    border: 1px solid var(--dark-dark);
    background-color: var(--white-dark);
    display: none;
}

.boxScreen .spanColumn:hover .spanColumnHover { 
    display: block;
    text-align: left;
}

.spanDanger {
    background-color: #dc3545;
    padding: 4px 12px;
    border-radius: 5px;
    color: var(--white-light);
    font-size: 14px;
    margin: 10px auto;
}