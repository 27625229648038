.select {  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.select .selector {
    width: 100%;
    position: relative;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    border-radius: 20px;
    border-radius: 60px!important;
    margin: 10px;
}

.selector select {
    height: 100%;
    width: 100%;
    outline: none;
    box-shadow: none;
    background: var(--white-dark)!important;
    border-radius: 60px!important;
    border: 2px solid var(--white-dark)!important;
    color: var(--dark-dark);
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
}

.select .selector .icon {
    color: var(--dark-dark);
    position: absolute;
    right: 20px;
    font-size: 32px;
    float: right;
}

.select .label {
    color: var(--second-color);
    font-size: 16px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    margin-left: 10px;
    width: 100%;
}

.select .label .textLabel {
    color: var(--dark-dark);
    font-size: 16px;
    font-weight: 400;
}

.select .label .help {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    margin-left: 5px;
}

.select .label .help .message {
    max-width: 250px;
    background-color: var(--white-dark);
    border: 1px solid var(--white-dark);
    color: var(--dark-dark);
    padding: 10px;
    font-size: 12px;
}

.select .label .help .icon {
    color: var(--dark-dark);
    font-size: 22px;
}