:root {
  --primary-color: #7B68EE;
  --dark-dark: #353535;
  --dark-light: #949494;
  --white-light: #fff;
  --white-dark: #f2f2f2;
  --color-warning: #ffc107;
  --color-success: #28a745;
  --color-error: #dc3545;
  --color-information: #007bff;
}

*{
  padding: 0;
  margin: 0;
}

body {  
  background-color: var(--white-light)
}

::-webkit-scrollbar {
  display: none
}

button:focus-visible, button:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

select {
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
}
