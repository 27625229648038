.header{
    height: 80px;
    background-color: var(--third-color);
    border-bottom: 2px solid var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.header .brand {
    max-width: 55px;
}