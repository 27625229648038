.footer{
    height: 80px;
    background-color: #f2f2f2;
    border-top: 2px solid var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer .brand {
    max-width: 35px;
}