.panel { 
    height: 100vh;
    overflow-y: hidden;
}

.panel .content { 
    display: flex;
    height: calc(100vh - 164px);
    flex: 1;
}

::-webkit-scrollbar {
    width: 4px;
}
 
::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}
 
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: var(--primary-color); 
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

::-webkit-scrollbar-thumb:window-inactive {
	background: var(--primary-color); 
}