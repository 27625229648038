.button {
    background-color: var(--primary-color);
    width: 100%;
    border-radius: 40px!important;
    padding: 10px;
    border: 2px solid var(--primary-color);
    color: var(--white-light);
    font-weight: 700;
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
    text-transform: lowercase!important;
}

.button:hover {
    border: 2px solid var(--primary-color);
    background-color: var(--white-dark);
    color: var(--dark-dark);
    transition: 3s!important;
}