.anchor {
    color: var(--white-dark);
    text-decoration: none;
    margin: 10px;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    border: none;
}

.anchor:hover {
    color: var(--primary-color);
    transition: 2s;
}