.check {
    background-color: var(--white-light);
    width: 16px;
    height: 16px;
    border-radius: 2px;
    padding: 10px;
    border: 2px solid var(--primary-color);
    color: var(--white-light);
    font-weight: 700;
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
    text-transform: lowercase!important;
    transition: background-color .6s;
    padding: 0;
}

.check.active {
    background-color: var(--primary-color);
}

.check:focus {
    border: 2px solid var(--primary-color);
}